@use "../../../../../assets/stylesheets/styles" as *;

.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      align-self: center;
    }
  }

  .Branding {
    align-self: flex-start;
    width:100%;
  }
  .Form {
    ft-button {
      width: rem-calc(100);;
    }
    .Heading,
    .Email {
      margin-bottom: rem-calc(25);
    }
    .Heading {
      font-size: rem-calc(24);
      margin-top: 0;
    }
    p {
      color: #{$color-primary-text};
    }
    .Login {
      margin-bottom: rem-calc(15);
    }
  }
  .Divider {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
    .Divider_or {
      margin: rem-calc(35 10);
    }
  }
  .Disclaimer {
    align-self: flex-end;
    color: #{$color-primary-text};
    font-size: $font-size-small;
    text-align: center;
  }
  .QuickPay {
    .QuickPay_heading {
      margin-bottom: rem-calc(25);
      margin-top: 0;
      font-size: rem-calc(24);
      color: #{$color-primary-text};
    }
    p {
      font-size: rem-calc(14);
      .QuickPay_nologin {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: $media-query-medium) {
}

@media only screen and (max-width: $media-query-small) {
}
