@use "../../../assets/stylesheets/styles" as *;

.Qpay_container {
  margin-bottom: rem-calc(16);
  --mdc-linear-progress-buffer-color: #{$color-primary-lighter};
  .Qpay_status {
    display: flex;
    justify-content: space-between;
    font-size: rem-calc(14);
    font-weight: $font-weight-bold;
    margin-bottom: rem-calc(28);
    .Qpay_status_active {
      color: #{$color-primary-text};
    }
    .Qpay_status_visited {
      color: #{$gray-secondary};
    }
    .Mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: $media-query-small) {
  .Qpay_container {
    .Qpay_status {
      .Web {
        display: none;
      }
      .Mobile {
        display: block;
      }
    }
  }
}
