/*  Highly recommend following a global and abstract naming convention for variables*/
/*
**     Define the variable naming convention rule
**
*/
$media-query-large: 1000px;
$media-query-medium: 768px;
$media-query-extra-medium: 600px;
$media-query-small: 430px;

$ft-left-bar-width: 65px;
$ft-nav-bar-height: 64px;
$ft-mobile-nav-bar-height: 56px;

//-Brand Colors----------------
$color-primary-lighter: var(--pp-theme-primary-lighter, #daecf5);
$color-primary-light: #0995d9;
$color-primary: #0482c5;
$color-primary-dark: var(--pp-theme-primary, #0471b1);
$color-primary-darker: #00528f;

$gray-secondary-lighter: #f7f7f6;
$gray-secondary-light: #ededec;
$gray-secondary: #737373;
$gray-secondary-dark: #6f6f6f;
$gray-secondary-darker: #2d2d2d;

$WHITE: #ffffff;
$BLACK: #000000;

$color-primary-text: var(--pp-theme-primary, #0471b1);
$color-default-text: var(--pp-default-text-color, #2d2d2d);

$nav-color-theme-primary: var(--pp-nav-theme-primary, $color-primary-darker);
$nav-font-color: var(--pp-nav-theme-text-color, #ffffff);

$footer-color-theme-primary: var(
  --pp-footer-theme-primary,
  $gray-secondary-darker
);
$footer-font-color: var(--pp-footer-font-color, $WHITE);
$footer-color-copyright: var(
  --pp-footer-color-copyright,
  $gray-secondary-light
);

$card-color-theme-primary: var(--pp-card-color-theme-primary, #2378bb);
$card-color-theme-secondary: var(--pp-card-color-theme-secondary, #5db09a);
$card-color-theme-tertiary: var(--pp-card-color-theme-tertiary, #215b8e);

$dashboard-gradient-bg-dark: var(
  --pp-dashboard-gradient-bg-dark,
  rgba(88, 158, 206, 1)
);
$dashboard-gradient-bg-light: var(
  --pp-dashboard-gradient-bg-light,
  rgba(148, 207, 179, 1)
);
$dashboard-slice-display: var(--pp-dashboard-slice-display, initial);

$ft-left-drawer-header-background: var(
  --pp-header-actionable-areas-background,
  var(--pp-theme-primary, #0471b1)
);
$user-action-background: var(
  --pp-header-actionable-areas-background,
  var(--pp-theme-primary, #0471b1)
);

$table-header-background-color: var(
  --pp-table-header-background,
  $gray-secondary
);
$table-header-text-color: var(
  --pp-table-header-text-color,
  $color-default-text
);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$default-notification-color: #fff9e6;

/* -------- */

//-COLORS----------------------
// I THINK EVERYTHING BELOW HERE CAN GO AWAY
//-Brand Colors----------------

//-Accent Colors---------------

/* $color-secondary-light: #85ce3f;
$color-secondary: #009b74;
$color-secondary-dark: #008563; */

$color-tertiary-lighter: #99d5ec;
$color-tertiary-light: #0097d1;
$color-tertiary: #2777b4;
$color-tertiary-dark: #1e5c8b;

$accent-eta-lighter: #ffcdd2;
$accent-eta-light: #ef9a9a;
$accent-eta: #f16865;
$accent-eta-dark: #b01120;
/* app used color */
$accent-eta-darker: #a41c18;
$accent-eta-dark-opacity: #b000201f;
/* app used color */

$accent-zeta-warn: #d12c27;

$accent-delta-lighter: #c5e1a5;
$accent-delta-light: #a9dc78;
$accent-delta: #5ecc45;
/* app used color */
$accent-delta-dark: #66a52a;
/* app used color */
$accent-delta-darker: #1f812d;
$accent-delta-dark-opacity: #52a3061f;
/* app used color */

//-FONTS---------------------------------------------

$font-size-base: 0.8125rem;
$font-size-giant: 2.25rem;
$font-size-huge: 2rem;
$font-size-big: 1.75rem;
$font-size-h1: 1.5rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1rem;
$font-size-h5: 0.8125rem;
$font-size-h6: 0.8125rem;
$font-size-small: 0.75rem;
$font-size-smaller: 0.6875rem;

//-SCAFFOLDING----------
$gutter: 30px;
$margin-base: 15px;
$padding-base: 15px;
$padding-alt: 20px;
$border-radius-base: 3px;
$border-radius: 8px;
$base-length: 10px;

//SHADOW---

$box-shadow-base: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
$border-radius: 4px;

// Print PDF css:

$print-header-color: #0483c5;
