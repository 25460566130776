@use "./assets/stylesheets/styles" as *;
@use "./assets/stylesheets/style.css" as fonts;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: rem-calc(16);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;

  --mdc-theme-primary: #{$color-primary-dark};
  color: #{$color-default-text};
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ft-nav {
  --mdc-theme-primary: #{$nav-color-theme-primary};
  // -webkit-overflow-scrolling: touch;
  position: -webkit-sticky;
  // transform: translate3d(0,0,0);
  // -webkit-transform: matrix3d(0, 0, 0);
}

ft-button.outline-button {
  --mdc-button-outline-color: #{$color-primary-dark};
}

ft-radio,
ft-checkbox,
ft-switch {
  --mdc-theme-secondary: #{$color-primary-dark};
  --mdc-theme-primary: #{$color-primary-dark};
}
ft-switch {
  --mdc-switch-selected-handle-color: #{$color-primary-dark};
  --mdc-switch-selected-focus-handle-color: #{$color-primary-dark};
  --mdc-switch-selected-hover-handle-color: #{$color-primary-dark};
  --mdc-switch-selected-track-color: #{$color-primary-lighter};
  --mdc-switch-selected-focus-track-color: #{$color-primary-lighter};
  --mdc-switch-selected-hover-track-color: #{$color-primary-lighter};
}

div[class^="rs-"] {
  --rs-text-primary: #{$color-default-text};
  --rs-input-focus-border: #{$color-primary-dark};
  --rs-bg-active: #{$color-primary-dark};
  --rs-state-focus-shadow: none;
  --rs-picker-value: #{$color-default-text};
  --rs-listbox-option-selected-text: #{$color-primary-dark};
  --rs-listbox-option-hover-text: #{$color-default-text};
  --rs-listbox-option-hover-bg: #{$gray-secondary-lighter};
  --rs-listbox-option-selected-bg: #{$gray-secondary-lighter};
  --rs-state-hover-bg: #{$gray-secondary-lighter};
}

.gray-background {
  --rs-bg-card: #{$table-header-background-color};
}

/* .highlighted-raw {
  background-color: #{$gray-secondary-lighter};
} */

/* end */

/* START //Date Picker global style
 */
/* .rs-picker-toolbar {
  display: none;
}

.rs-picker-date-menu {
  --rs-picker-value: #{$color-primary-dark};
  --rs-bg-active: #{$color-primary-dark};
  --rs-input-focus-border: #{$color-primary-dark};
  --rs-state-focus-shadow: none;
} */

/* END */

/* Start */

/* .rsuite-custom {
  --rs-menuitem-active-text: #{$color-primary-dark}; */
.rs-dropdown-item {
  display: flex;
  align-items: center;
  ft-icon {
    margin-right: rem-calc(10);
  }
}
.rs-dropdown-menu {
  border-radius: #{$border-radius};
  box-shadow: #{$box-shadow-base};
}

/* } */

/* End */

/* start Print css */

.print-css {
  display: none;
}

@media only print {
  body [id="root"] {
    display: none;
  }

  div[id="portalContainer"] {
    display: block;
  }

  .print-css {
    display: block;
    background-color: $WHITE;
    margin: auto;
    max-width: 560px;
    text-align: center;
  }
}
/* end Print css */
