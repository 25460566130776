@use "../../../assets/stylesheets/styles" as *;

.Container {
  font-size: rem-calc(16);
  margin: rem-calc(24);
  max-height: 60vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  &.OverflowVisible {
    overflow: visible;
  }
  .CloseButton {
    position: absolute;
    top: rem-calc(15);
    right: rem-calc(15);
    user-select: none;
    cursor: pointer;
  }

  .Indication {
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(24);

    .Icon_wrapper {
      width: rem-calc(50);
      height: rem-calc(50);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #{$color-primary-dark};
      opacity: 0.4;

      &.Warning {
        background-color: #{$accent-eta-dark};
      }

      &.Error {
        background-color: #{$accent-eta-dark};
      }
    }

    .Icon {
      position: absolute;
      color: #{$color-primary-text};
      width: rem-calc(50);
      height: rem-calc(50);
      display: flex;
      justify-content: center;
      align-items: center;

      &.Warning {
        color: #{$accent-eta-dark};
      }

      &.Error {
        color: #{$accent-eta-dark};
      }
    }
  }

  text-align: center;

  .Action_buttons {
    margin-top: rem-calc(24);

    .Cancel {
      margin-right: rem-calc(15);
    }

    .Warning {
      --mdc-theme-primary: #{$accent-eta-dark};
    }
  }
}
