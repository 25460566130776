@use "../../../assets/stylesheets/styles" as *;

.Container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  p {
    font-size: rem-calc(26);
  }
  .PageHeading {
    font-size: rem-calc(40);
  }
  .BottomLine {
    margin-top: rem-calc(28);
  }
}

.DialogText {
  font-size: rem-calc(16);
}

@media only screen and (max-width: 729px) {
}
