@use "../../../../assets/stylesheets/styles" as *;

.Default {
  min-height: rem-calc(60);
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #{$color-primary-text};
  background-color: #{$default-notification-color};
  font-size: rem-calc(14);
  .Messages {
    margin: rem-calc(3);
  }
  a {
    margin: 0 5px 0 5px;
  }

  &.Error {
    color: #{$accent-eta-dark};
    background-color: #{$accent-eta-lighter};
  }

  &.Success {
    color: #{$accent-delta-dark};
    background-color: #{$accent-delta-dark-opacity};
  }
}
