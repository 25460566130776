@use "../../../assets/stylesheets/styles" as *;

.Wrapper {
  height: 100%;
  display: grid;
  grid-template: 63px 1fr / 65px 1fr;
  .HeadWrapper {
    grid-column: 2;
    border-bottom: 1px solid #{$gray-secondary-light};
    display: flex;
    align-items: center;
    padding-left: rem-calc(25);
    .Logo {
      max-height: rem-calc(50);
      object-fit: scale-down;
    }
  }
  .Container {
    grid-row: 2;
    grid-column: 2;
  }
}

@media only screen and (max-width: 1025px) {
}

@media only screen and (max-width: $media-query-medium) {
}

@media only screen and (max-width: $media-query-small) {
}
