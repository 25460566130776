@use "../../../assets/stylesheets/styles" as *;

.Wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: calc(100vh - rem-calc(129));
  .Wrapper_left {
    background-image: var(--pp-theme-primary-image);
    background-size: cover;
    .Wrapper_left_message {
      color: #{$WHITE};
      margin: 5% 30% 0 30%;
      h2 {
        font-size: rem-calc(35);
        font-weight: #{$font-weight-light};
      }
    }
  }
  .Wrapper_right {
    display: flex;
    flex-direction: column;
    background-color: #{$WHITE};
    height: calc(100vh - rem-calc(129));
    overflow: auto;
    overflow-x: hidden;
    .Wrapper_right_container {
      padding: 5% 10% 10% 10%;
      flex: 1;
    }
    .Container {
      height: 100%;
    }

    .Wrapper_right_container_center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .Wrapper_right_disclaimer {
      display: flex;
      font-size: rem-calc(14);
      padding: rem-calc(15);
      .Wrapper_right_disclaimer_icon {
        flex-basis: rem-calc(90);
      }
      div {
        padding: rem-calc(10);
      }
      p {
        margin: 0;
      }
    }
  }
}
.Small {
  grid-template-columns: 2fr 1fr;
}

@media only screen and (max-width: $media-query-large) {
  .Small {
    grid-template-columns: 1fr 1fr;
    .Wrapper_right_container {
      padding: 10% 20%;
      flex: 1;
    }
  }
}

@media only screen and (max-width: $media-query-medium) {
  .Wrapper {
    display: grid;
    grid-template-columns: 1fr;
    .Wrapper_right {
      background-image: var(--pp-theme-primary-image);
      background-size: cover;
      .Container {
        padding: 10%;
        background-color: #{$WHITE};
      }
      .Wrapper_right_disclaimer {
        background-color: #{$WHITE};
        opacity: 0.7;
      }
    }
    .Wrapper_left {
      display: none;
    }
  }
}

@media only screen and (max-width: $media-query-small) {
  .Wrapper {
    display: grid;
    grid-template-columns: 1fr;
    .Wrapper_right {
      height: calc(100vh - rem-calc(180));
      background-image: none;
      .Container {
        padding: 0;
        background-color: none;
        margin-bottom: 60px;
      }
      .Wrapper_right_disclaimer {
        background-color: none;
        opacity: none;
      }
    }
    .Wrapper_left {
      display: none;
    }
  }
}
