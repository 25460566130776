@use "../../../assets/stylesheets/styles"as *;

.Wrapper {
  padding: rem-calc(10);
  background: #{$WHITE};
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .Divider {
    margin-top: 10px;
  }

  .Heading {
    font-size: rem_calc(20);
    text-align: center;
    text-transform: capitalize;
    margin: 0;
  }
}