@use "../../../assets/stylesheets/styles" as *;

.Container {
  ul {
    list-style-type: none;
    padding: 0;
    li {
      padding: rem-calc(18 20);
      font-size: rem-calc(18);
      cursor: pointer;
      &:hover {
        background-color: #{$gray-secondary-lighter};
      }
    }
  }
}
