@use "../../../assets/stylesheets/styles" as *;

.Wrapper {
  padding-left: #{$ft-left-bar-width};
  transition: 0.2s padding ease-in;
  box-sizing: border-box;

  .Header {
    display: flex;
    height: #{$ft-nav-bar-height};
    background-color: #{$ft-left-drawer-header-background};
    align-items: center;
    justify-content: flex-end;
    padding-right: rem-calc(20);

    .Icon {
      color: #{$WHITE};
      cursor: pointer;
    }
  }

  .Container {
    height: calc(100vh - #{$ft-nav-bar-height});
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    .Heading {
      background-color: #{$gray-secondary-light};
      font-size: rem-calc(28);
      margin: 0;
      padding: rem-calc(20);
      text-align: center;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .InnerWrapper {
      margin: rem-calc(50);
    }
  }
}

.NavOpen {
  padding-left: $ft-left-bar-width + 120;
}

@media only screen and (max-width: 599px) {
  .Wrapper {
    .Header {
      height: #{$ft-mobile-nav-bar-height};
    }
  }
}

@media only screen and (max-width: $media-query-small) {
  .Wrapper {
    padding-left: 0;
  }
}
