@forward "./mixins/rem-calc";
@forward "./variables/theme-variables";

// Putting this here for now, but we should adjust in the FT UI later and REMOVE THIS
.uppercase {
  text-transform: uppercase;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.WidgetMargin {
  margin-left: rem-calc(20);
  margin-right: rem-calc(20);
}